import { Alert } from 'antd'
import React from 'react'

export const defaultSelectedSchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Название'
    },
    default_rating: {
      type: 'number',
      title: 'Рейтинг по умолчанию'
    },
    order_percentage: {
      type: 'number',
      title: 'Процент от заказа'
    },
    organization_id: {
      type: 'string',
      title: 'Организация',
      view: {
        name: 'select-from-enum'
      },
      options: {
        disabled: true
      }
    },
    address: {
      type: 'string',
      title: 'Адрес',
      view: {
        name: 'input-text-dadata'
      },
      options: {
        to_bound: 'flat',
        rows: 2,
      }
    },
    region: {
      type: 'string',
      title: 'Регион',
      options: {
        disabled: true
      }
    },
    city: {
      type: 'string',
      title: 'Город',
      options: {
        disabled: true
      }
    },
    district: {
      type: 'string',
      title: 'Округ',
      options: {
        disabled: true
      }
    },
    phone: {
      type: 'string',
      title: 'Номер телефона',
      format: 'phone'
    },
    email: {
      type: 'string',
      title: 'E-mail'
    },
    telegram_chat_id:{
      type: 'string',
      title: 'Telegram: ID чата'
    },
    devider_ct: {
      type: 'devider',
      style: { gridColumn: '1 / -1' },
      content: (
        <Alert
          message='Интеграция Calltouch'
          description={(<div>
            Тут инструкция по интеграции с Манго
          </div>)}
          type='info'
        />
      )},
    calltouch_site_id: {
      type: 'string',
      title: 'Calltouch: site ID'
    },
    calltouch_token: {
      type: 'string',
      title: 'Calltouch: profile token'
    },
    devider_mango: {
      type: 'devider',
      style: { gridColumn: '1 / -1' },
      content: (
        <Alert
          message='Интеграция Манго'
          description={(<div>
            Тут инструкция по интеграции с Манго
          </div>)}
          type='info'
        />
      )},
    mango_domain: {
      type: 'string',
      title: 'Mango Office: домен'
    },
    mango_api: {
      type: 'string',
      title: 'Mango Office: Уникальный код АТС'
    },
    mango_salt: {
      type: 'string',
      title: 'Mango Office: Ключ для создания подписи'
    },
    devider_avito: {
      type: 'devider',
      style: { gridColumn: '1 / -1' },
      content: (
      <Alert
        message='Интеграция АВИТО'
        description={(<div>
          В <a href='https://www.avito.ru/profile' target='_blank'>Личном кабинете</a> указан <b>номер профиля</b>.<br />
          На странице <a href='https://www.avito.ru/professionals/api' target='_blank'>Интеграции</a> указаны <b>client_id</b> и <b>client_secret</b>.<br />
          Для настройки интеграции с авито скопируйте их и вставте в поля, ниже.
        </div>)}
        type='info'
        />
      )},
    avito_account_id: {
      type: 'string',
      title: 'Авито: номер профиля',
    },
    avito_client_id: {
      type: 'string',
      title: 'Авито: client_id',
    },
    avito_client_secret: {
      type: 'string',
      title: 'Авито: client_secret',
    },
    devider_closer: {
      type: 'devider',
      style: { gridColumn: '1 / -1' },
      content: (<></>)},
    created_at: {
      type: 'string',
      format: 'date-time',
      title: 'Дата и время создания',
      view: {
        name: 'created_at'
      },
      options: {
        disabled: true
      }
    }
  }
}
