import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, message, Modal, Spin } from 'antd'
import { DefaultJsonForm } from '../../../../../../components/v2/default-json-form'
import schema from './schema.json'
import { convertData2JsonForm } from '../../../../../../shared/lib/convertData2JsonForm'
import fetchAPI from '../../../../../../../lib/utils/fetch-api'
import { isFormDataRequired } from '../../../../../../../lib/utils/collections'

interface IFormData {
  manufacture_organization?: number
  manufacture_office?: number
  manufacture_responsible?: number
  delivery_date?: string
  montage_specification_scan?: string
  mounting_organization?: number
  mounting_office?: number
  mounting_responsible?: string
  production_number?: string
  mounting_date?: string
  mounting_time?: string
  mounting: boolean
  comment?: string
}

interface ManufactureModalProps {
  id: number | string
  isModalOpen: boolean
  onOk?: ((formData: IFormData) => void) | undefined
  onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
}

export const ManufactureModal: React.FC<ManufactureModalProps> = memo((props: ManufactureModalProps) => {
  const { id, isModalOpen, onOk, onCancel } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [enums, setEnums] = useState<any>({})
  const [current, setCurrent] = useState<{
    manufacture: {
      organization?: number,
      office?: number
    },
    mounting: {
      organization?: number,
      office?: number
    }
  }>({
    manufacture: {
      organization: undefined,
      office: undefined,
    },
    mounting: {
      organization: undefined,
      office: undefined,
    }
  })
  const [formData, setFormData] = useState<IFormData>({
    manufacture_organization: undefined,
    manufacture_office: undefined,
    manufacture_responsible: undefined,
    delivery_date: undefined,
    montage_specification_scan: undefined,
    mounting_organization: undefined,
    mounting_office: undefined,
    mounting_responsible: undefined,
    production_number: undefined,
    mounting_date: undefined,
    mounting_time: undefined,
    mounting: false,
    comment: undefined,
  })

  const fetchEnums = useCallback(async () => {
    setLoading(true)
    try {
      const res = await fetchAPI(`/api/order/${id}/manufacture`)
      setEnums(res.enums)
      formData.production_number = res?.data?.production_number
    } catch (error) {
      message.error('Ошибка при загрузке данных')
      setError('Ошибка при загрузке данных')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isModalOpen) {
      fetchEnums()
    }
  }, [isModalOpen, fetchEnums])

  const handleOk = useCallback(() => {
    const isRequiredFields = isFormDataRequired(formData, actualSchema)
    if (!isRequiredFields) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля'
      })
      return
    }
    onOk?.(formData)
  }, [formData])

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e)
  }

  const onChange = useCallback(data => {
    setFormData(prevData => {

      const manufacture_office = current.manufacture.organization !== data.formData.manufacture_organization ? undefined : data.formData.manufacture_office
      const manufacture_responsible = current.manufacture.office !== data.formData.manufacture_office ? undefined : data.formData.manufacture_responsible
      const mounting_office = current.mounting.organization !== data.formData.mounting_organization ? undefined : data.formData.mounting_office
      const mounting_responsible = current.mounting.office !== data.formData.mounting_office ? undefined : data.formData.mounting_responsible

      setCurrent({
        manufacture: {
          organization: data.formData.manufacture_organization,
          office: data.formData.manufacture_office,
        },
        mounting: {
          organization: data.formData.mounting_organization,
          office: data.formData.mounting_office,
        },
      })

      return {
        ...prevData,
        ...data.formData,
        manufacture_office,
        manufacture_responsible,
        mounting_office,
        mounting_responsible,
      }
    })
  }, [setFormData, current, setCurrent])

  interface ISchemaActual {
    resultObject: {}
    resultSchema: {
      properties: {
        manufacture_organization: any
        manufacture_office: any
        manufacture_responsible: any
        delivery_date: any
        montage_specification_scan: any
        mounting_organization: any
        mounting_office: any
        mounting_responsible: any
        production_number: any
        mounting_date: any
        mounting_time: any
        mounting: any
        comment: any
      }
      required: string[]
    }
  }

  const actualSchema = useMemo(() => {
    const schemaActual = convertData2JsonForm(schema.fields) as never as ISchemaActual
    const {
      manufacture_organization,
      manufacture_office,
      mounting_organization,
      mounting_office,
    } = formData

    if (enums?.manufacture_organizations?.length) {
      schemaActual.resultSchema.properties.manufacture_organization.oneOf = enums.manufacture_organizations
        .map((organization: any) => ({ const: organization.id, title: organization.value }))
      schemaActual.resultSchema.properties.manufacture_organization.enum = schemaActual.resultSchema.properties.manufacture_organization.oneOf
        .reduce((acc, curr) => {
          acc.push(curr.const)
          return acc
        }, [])
    }

    if (enums?.mounting_organizations?.length) {
      schemaActual.resultSchema.properties.mounting_organization.oneOf = enums.mounting_organizations
        .map((organization: any) => ({ const: organization.id, title: organization.value }))
      schemaActual.resultSchema.properties.mounting_organization.enum = schemaActual.resultSchema.properties.mounting_organization.oneOf
        .reduce((acc, curr) => {
          acc.push(curr.const)
          return acc
        }, [])
    }

    if (schemaActual.resultSchema && schemaActual.resultSchema.properties) {
      if (manufacture_organization) {
        schemaActual.resultSchema.properties.manufacture_office.oneOf = enums.manufacture_offices
          .filter((office: any) => office.organization_id === manufacture_organization)
          .map((office: any) => ({ const: office.id, title: office.value }))
        schemaActual.resultSchema.properties.manufacture_office.enum = schemaActual.resultSchema.properties.manufacture_office.oneOf
          .reduce((acc, curr) => {
            acc.push(curr.const)
            return acc
          },[])
        schemaActual.resultSchema.properties.manufacture_office.options.disabled = false
      }

      if (manufacture_office) {
        schemaActual.resultSchema.properties.manufacture_responsible.oneOf = enums.manufacture_managers
          .filter((manager: any) => manager.office_id === manufacture_office)
          .map((manager: any) => ({ const: manager.id, title: manager.value }))
        schemaActual.resultSchema.properties.manufacture_responsible.enum = schemaActual.resultSchema.properties.manufacture_responsible.oneOf
          .reduce((acc, curr) => {
            acc.push(curr.const)
            return acc
          },[])
        schemaActual.resultSchema.properties.manufacture_responsible.options.disabled = false
      }

      if (mounting_organization) {
        schemaActual.resultSchema.properties.mounting_office.oneOf = enums.mounting_offices
          .filter((office: any) => office.organization_id === mounting_organization)
          .map((office: any) => ({ const: office.id, title: office.value }))
        schemaActual.resultSchema.properties.mounting_office.enum = schemaActual.resultSchema.properties.mounting_office.oneOf
          .reduce((acc, curr) => {
            acc.push(curr.const)
            return acc
          },[])
        schemaActual.resultSchema.properties.mounting_office.options.disabled = false
      }

      if (mounting_office) {
        schemaActual.resultSchema.properties.mounting_responsible.oneOf = enums.mounting_managers
          .filter((manager: any) => manager.office_id === mounting_office)
          .map((manager: any) => ({ const: manager.id, title: manager.value }))
        schemaActual.resultSchema.properties.mounting_responsible.enum = schemaActual.resultSchema.properties.mounting_responsible.oneOf
          .reduce((acc, curr) => {
            acc.push(curr.const)
            return acc
          },[])
        schemaActual.resultSchema.properties.mounting_responsible.options.disabled = false
      }

      if (!formData.mounting) {
        delete schemaActual.resultSchema.properties.montage_specification_scan
        delete schemaActual.resultSchema.properties.mounting_organization
        delete schemaActual.resultSchema.properties.mounting_office
        delete schemaActual.resultSchema.properties.mounting_responsible
        delete schemaActual.resultSchema.properties.production_number
        delete schemaActual.resultSchema.properties.mounting_date
        delete schemaActual.resultSchema.properties.mounting_time
        delete schemaActual.resultSchema.properties.comment
        schemaActual.resultSchema.required = schemaActual.resultSchema.required.filter(
          item => ![
            'mounting_organization',
            'mounting_office',
            'montage_specification_scan',
            'mounting_responsible',
            'production_number',
            'mounting_date',
            'mounting_time',
            'comment',
          ].includes(item)
        )
      }
    }

    return schemaActual.resultSchema || {}
  }, [schema, formData, enums])

  if (!isModalOpen) {
    return null
  }

  return (
    <Modal
      title={schema.title}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={isModalOpen}
      destroyOnClose={true}
      okButtonProps={{
        disabled: loading
      }}
    >
      {loading ? (
        <Spin />
      ) : (
        error
          ? (<Alert message={error} type='error' showIcon />)
          : (
            <DefaultJsonForm
              formData={formData}
              schema={actualSchema}
              onChange={onChange}
              orientation='horizontal'
            />
          )
      )}
    </Modal>
  )
})
