import React, { useState } from 'react'
import { Modal, Select, Form, Button, message } from 'antd'
import fetchAPI from '../../../../../../lib/utils/fetch-api'
import { useHistory } from 'react-router-dom'

const { Option } = Select

interface Office {
  id: number
  name: string
}

interface Organization {
  id: number
  name: string
  offices: Office[]
}

interface ChangeRegionModalProps {
  id: string | number
  disabled: boolean
}

interface IFormData {
  organization: number | null
  office: number | null
}

const ChangeRegionModal: React.FC<ChangeRegionModalProps> = ({ id, disabled })  => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const history = useHistory()
  const [formData, setFormData] = useState<IFormData>({
    organization: null,
    office: null
  })
  const [organizations, setOrganizations] = useState<Organization[]>([])

  const fetchOrganizations = async () => {
  await fetchAPI('/api/change_office')
    .then(res => {
      setOrganizations(res.organization)
    })
    .catch(error => {
      message.error('Ошибка при загрузке данных', error)
    })
  }

  const handleOpenModal = () => {
    setFormData( prevData => ({...prevData, organization: null, office: null }))
    fetchOrganizations()
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOrgChange = (value: number) => {
    const selectedOrg = organizations.find(org => org.id === value)
    setFormData({ organization: value, office: null })

    if (selectedOrg) {
      setFormData(prevData => ({
        ...prevData,
        office: null,
      }))
    }
  }

  const handleOfficeChange = (value: number) => {
    setFormData(prevData => ({ ...prevData, office: value }))
  }

  const handleSubmit = async () => {
    if (!formData.organization || !formData.office) {
      message.error('Выберите организацию и офис.')
      return
    }

    await fetchAPI('/api/change_office', {
        method: 'POST',
        body: JSON.stringify({
          office: formData.office,
          id,
        }),
      })
      .then(() => {
        handleCloseModal()
        history.push('/orders')
      })
      .catch(error => {
        message.error('Ошибка при сохранении данных', error)
      })
  }

  return (
    <>
      <Button onClick={handleOpenModal} disabled={disabled}>Передать в другой офис</Button>
      <Modal
        title='Выбор региона'
        visible={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form layout='vertical'>
          <Form.Item label='Организация' required>
            <Select
              value={formData.organization}
              onChange={handleOrgChange}
              placeholder='Выберите организацию'
              allowClear
            >
              {organizations.map(org => (
                <Option key={org.id} value={org.id}>{org.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label='Офис' required>
            <Select
              value={formData.office}
              onChange={handleOfficeChange}
              placeholder='Выберите офис'
              allowClear
              disabled={!formData.organization}
            >
              {formData.organization && organizations
                .find(org => org.id === formData.organization)?.offices.map(office => (
                  <Option key={office.id} value={office.id}>{office.name}</Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type='primary' onClick={handleSubmit}>
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ChangeRegionModal
