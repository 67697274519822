import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, message, Modal, Spin } from 'antd'
import { DefaultJsonForm } from '../../../../../../components/v2/default-json-form'
import schema from './schema.json'
import { convertData2JsonForm } from '../../../../../../shared/lib/convertData2JsonForm'
import fetchAPI from '../../../../../../../lib/utils/fetch-api'
import { isFormDataRequired } from '../../../../../../../lib/utils/collections'

interface IFormData {
  measurement_organization?: number
  measurement_office?: number
  measurement_responsible?: number
  measurement_date?: string
  measurement_time?: string
  measurement_scan?: string
  comment?: string
  send_tg_notification: boolean
}

interface MeasurementModalProps {
  id: number | string
  isModalOpen: boolean
  onOk?: ((formData: IFormData) => void) | undefined
  onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
}

export const MeasurementModal: React.FC<MeasurementModalProps> = memo((props: MeasurementModalProps) => {
  const { id, isModalOpen, onOk, onCancel } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [enums, setEnums] = useState<any>({})
  const [customers, setCustomers] = useState<any>({})
  const [current, setCurrent] = useState<{
    organization?: number,
    office?: number
  }>({
    organization: undefined,
    office: undefined,
  })
  const [formData, setFormData] = useState<IFormData>({
    measurement_organization: undefined,
    measurement_office: undefined,
    measurement_responsible: undefined,
    measurement_date: undefined,
    measurement_time: undefined,
    measurement_scan: undefined,
    comment: undefined,
    send_tg_notification: false,
  })

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const res = await fetchAPI(`/api/order/${id}/measurement`)
      setEnums(res.enums)
      setCustomers(res.customers)
    } catch (error) {
      message.error('Ошибка при загрузке данных')
      setError('Ошибка при загрузке данных')
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    if (isModalOpen) {
      fetchData()
    }
  }, [isModalOpen, fetchData])

  const handleOk = useCallback(() => {
    const isRequiredFields = isFormDataRequired(formData, actualSchema)
    if (!isRequiredFields) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля'
      })
      return
    }
    onOk?.(formData)
  }, [formData])

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e)
  }

  const onChange = useCallback(data => {
    setFormData(prevData => {

      const measurement_office = current.organization !== data.formData.measurement_organization ? undefined : data.formData.measurement_office
      const measurement_responsible = current.office !== data.formData.measurement_office ? undefined : data.formData.measurement_responsible

      setCurrent({
        organization: data.formData.measurement_organization,
        office: data.formData.measurement_office,
      })

      return {
        ...prevData,
        ...data.formData,
        measurement_responsible,
        measurement_office,
      }
    })
  }, [setFormData, current, setCurrent])

  interface ISchemaActual {
    resultObject: {}
    resultSchema: {
      properties: {
        measurement_organization: any
        measurement_office: any
        measurement_responsible: any
        send_tg_notification: any
        measurement_time: any
        measurement_scan: any
        comment: any
      }
      required: string[]
    }
  }

  const actualSchema = useMemo(() => {
    const schemaActual = convertData2JsonForm(schema.fields) as never as ISchemaActual
    const { measurement_organization, measurement_office, measurement_responsible } = formData

    if (enums?.organizations?.length) {
      schemaActual.resultSchema.properties.measurement_organization.oneOf = enums.organizations
        .map((organization: any) => ({ const: organization.id, title: organization.value }))
      schemaActual.resultSchema.properties.measurement_organization.enum = schemaActual.resultSchema.properties.measurement_organization.oneOf
        .reduce((acc, curr) => {
          acc.push(curr.const)
          return acc
        }, [])
    }

    if (schemaActual.resultSchema && schemaActual.resultSchema.properties) {
      if (measurement_organization) {
        schemaActual.resultSchema.properties.measurement_office.oneOf = enums.offices
          .filter((office: any) => office.organization_id === measurement_organization)
          .map((office: any) => ({ const: office.id, title: office.value }))
        schemaActual.resultSchema.properties.measurement_office.enum = schemaActual.resultSchema.properties.measurement_office.oneOf
          .reduce((acc, curr) => {
            acc.push(curr.const)
            return acc
          },[])
        schemaActual.resultSchema.properties.measurement_office.options.disabled = false
      }

      if (measurement_office) {
        schemaActual.resultSchema.properties.measurement_responsible.oneOf = enums.managers
          .filter((manager: any) => manager.office_id === measurement_office)
          .map((manager: any) => ({ const: manager.id, title: manager.value }))
        schemaActual.resultSchema.properties.measurement_responsible.enum = schemaActual.resultSchema.properties.measurement_responsible.oneOf
          .reduce((acc, curr) => {
            acc.push(curr.const)
            return acc
          },[])
        schemaActual.resultSchema.properties.measurement_responsible.options.disabled = false
      }
      if (
        customers &&
        Array.isArray(measurement_responsible) &&
        measurement_responsible?.length === 1 &&
        customers.includes(measurement_responsible[0])
      ) {
        delete schemaActual.resultSchema.properties.send_tg_notification
        delete schemaActual.resultSchema.properties.measurement_time
        delete schemaActual.resultSchema.properties.comment
        schemaActual.resultSchema.required = schemaActual.resultSchema.required.filter(item => item !== 'comment' && item !== 'measurement_time')
      } else {
        delete schemaActual.resultSchema.properties.measurement_scan
        schemaActual.resultSchema.required = schemaActual.resultSchema.required.filter(item => item !== 'comment' && item !== 'measurement_scan')
      }
    }

    return schemaActual.resultSchema || {}
  }, [schema, formData, enums])

  if (!isModalOpen) {
    return null
  }

  return (
    <Modal
      title={schema.title}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={isModalOpen}
      destroyOnClose={true}
      okButtonProps={{
        disabled: loading
      }}
    >
      {loading ? (
        <Spin />
      ) : (
        error
          ? (<Alert message={error} type='error' showIcon />)
          : (
            <DefaultJsonForm
              formData={formData}
              schema={actualSchema}
              onChange={onChange}
              orientation='horizontal'
            />
          )
      )}
    </Modal>
  )
})
