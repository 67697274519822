import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const COLORS = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF',
    '#FF4560', '#00E396', '#775DD0', '#FF6347', '#32CD32',
    '#FFD700', '#6A5ACD'
];

const processCancelReasonsData = (data) => {
    const total = data.reduce((sum, entry) => sum + entry.value, 0);
    return data.map(entry => ({
        ...entry,
        name: `${entry.name}: ${(entry.value / total * 100).toFixed(0)}%`,
        value: entry.value
    }));
};

const processArResultVacancyData = (data) => {
    const total = data.reduce((sum, entry) => sum + entry.count, 0);
    return data.map(entry => ({
        ...entry,
        name: `${entry.vacancy}: ${(entry.count / total * 100).toFixed(0)}%`,
        value: entry.count
    }));
};

const CancelReasonsPieChart = ({ cancelReasons, arResultVacancy }) => {
    const processedCancelReasons = processCancelReasonsData(cancelReasons);
    const processedArResultVacancy = processArResultVacancyData(arResultVacancy);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ResponsiveContainer width="50%" height={400}>
                <PieChart>
                    <Pie
                        data={processedCancelReasons}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {processedCancelReasons.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend layout="vertical" align="right" verticalAlign="middle" wrapperStyle={{ right: 0 }} />
                </PieChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="50%" height={400}>
                <PieChart>
                    <Pie
                        data={processedArResultVacancy}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {processedArResultVacancy.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend layout="vertical" align="right" verticalAlign="middle" wrapperStyle={{ right: 0 }} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CancelReasonsPieChart;
