import React, { memo, Suspense, useCallback, useState } from 'react'
import { HStack } from '../../../../../../ui/Stack'
import { Button } from 'antd'
import { classNames } from '../../../../../../lib/classNames/classNames'
import { ModalAddNote } from '../../../../../../views/modal-add-notes-new'
import { MontageEditDateModal } from '../../montage-edit-date-modal'
import { ModalCancel } from '../../../../../../views/modal-cancel'
import ModalNotAccepted from '../../modals/not-accepted'

interface MontageFormsHeaderProps {
  id: string | number,
  className?: string,
  stage?: number,
  isFinished?: boolean
  toOrder?: () => void,
  toClaim?: () => void,
  toFinish?: () => void,
  toNotAccepted?: (data: any) => void,
}

const titlesButton = [
  'Взять в работу',
  'Монтаж произведен',
  'Монтаж принят',
  'Выполнено',
]

export const MontageFormsHeader: React.FC<MontageFormsHeaderProps> = memo((props: MontageFormsHeaderProps) => {
  const {
    className,
    id,
    stage = 0,
    toOrder,
    toFinish,
    isFinished,
    toNotAccepted,
  } = props
  const isDisabledButtons = id === 'new'

  const [isOpenModalAddNote, setIsOpenModalAddNote] = useState<boolean>(false)
  const [isOpenModalMontageEditDate, setIsOpenModalMontageEditDate] = useState<boolean>(false)
  const [isOpenModalCancel, setIsOpenModalCancel] = useState<boolean>(false)

  const handleNextStageOnClick = useCallback(async () => {
    if (toOrder) {
      await toOrder?.()
    }
  }, [toOrder])

  const handleNotAcceptedStage = useCallback(async (data: any) => {
    if (toNotAccepted) {
      await toNotAccepted?.(data)
    }
  }, [toNotAccepted])

  const onCloseAddNote = useCallback(() => {
    setIsOpenModalAddNote(false)
  }, [setIsOpenModalAddNote])

  const onCloseMontageEditDateModal = useCallback(() => {
    setIsOpenModalMontageEditDate(false)
  }, [setIsOpenModalMontageEditDate])

  const handleFinishOnClick = useCallback(async () => {
    if (toFinish) {
      await toFinish?.()
    }
  }, [toFinish])

  const handleCancelOnClick = useCallback(() => {
    setIsOpenModalCancel(true)
  }, [setIsOpenModalCancel])

  const onCloseCancel = useCallback(() => {
    setIsOpenModalCancel(false)
  }, [setIsOpenModalCancel])

  return (
    <HStack gap={'8'} className={classNames('mapWrapper', {}, [className])}>
      <HStack gap={'8'} max justify={'start'} className={'h3'}>
        <>Монтаж {`№ ${id}`}</>
      </HStack>
      <HStack gap={'8'} max justify={'end'}>
        <Button
          onClick={() => setIsOpenModalAddNote(true)}
          disabled={isDisabledButtons}
        >
          Добавить заметку
        </Button>
        {/*<Suspense fallback={''}>
          <Button
            onClick={() => setIsOpenModalCreateTask(true)}
            disabled={isDisabledButtons}
          >
            Создать задачу
          </Button>
          <ModalCreateTask
            isOpen={isOpenModalCreateTask}
            onClose={onCloseCreateTask}
            entityType={'case'}
            entityId={id}
          />
        </Suspense>*/}
        <ModalAddNote
          entityId={Number(id)}
          entityType={'case'}
          isOpen={isOpenModalAddNote}
          onClose={onCloseAddNote}
        />
        {
          stage === 1 && (
            <>
              <Button
                onClick={() => setIsOpenModalMontageEditDate(true)}
                style={{marginBottom: 16, marginTop: 16}}
                type='default'
              >
                Изменить дату
              </Button>
              <MontageEditDateModal
                id={id}
                isOpen={isOpenModalMontageEditDate}
                onCancel={onCloseMontageEditDateModal}
              />
            </>
          )
        }
        {
          titlesButton[stage] && (
            <Button
              onClick={handleNextStageOnClick}
              style={{marginBottom: 16, marginTop: 16}}
              type='default'
            >
              {titlesButton[stage] ?? ''}
            </Button>
          )
        }
        {stage === 2 && (
          <Suspense fallback={''}>
            <ModalNotAccepted
              onOk={handleNotAcceptedStage}
            />
          </Suspense>
        )}
        {!isDisabledButtons && (
          <Suspense fallback={''}>
            <Button
              danger
              onClick={handleCancelOnClick}
            >
              Отмена
            </Button>
            <ModalCancel
              entityId={id}
              entityType='montage'
              isOpen={isOpenModalCancel}
              onClose={onCloseCancel}
            />
          </Suspense>
        )}
      </HStack>
    </HStack>
  )
})
