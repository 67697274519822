import React, { useState, useEffect, useMemo, useCallback, memo } from 'react'
import B2BLayout from '../../../layouts/b2b'
import { Table } from 'antd'
import { getColumns } from './columns'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { useHistory } from 'react-router-dom'
import FiltersList from '../../../components/v2/filters'
import { Sorter, State } from '../../../interfaces'
import { objectToUrl } from '../../utils'
import { currentFiltersProps, Enums, IEnum, IStage, TablePaginationPosition } from './interface'

const createSession = createSelector(
  (state: State) => state.session,
  sessionData => sessionData,
)

const OrdersList = () => {
  const history = useHistory()
  const [data, setData] = useState([])
  const [availableFilters, setAvailableFilters] = useState<Enums>({
    manager: [],
    stage: [],
    cancel_reason: [],
    order_attribute: [],
    organization: [],
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters] = useState<currentFiltersProps>({
    phone: urlParams?.phone,
    manager: urlParams?.manager ? +urlParams.manager : undefined,
    id: urlParams.id ? +urlParams.id : undefined,
    organization: urlParams.organization ? +urlParams.organization : undefined,
    stage: urlParams?.stage,
    dates_range: urlParams?.dates_range,
    dates_field: urlParams?.dates_field,
    cancel_reason: urlParams?.cancel_reason,
    order_attribute: urlParams?.order_attribute,
    customer_name: urlParams?.customer_name,
    production_number: urlParams?.production_number,
  })
  const { sessionData } = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1
  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [stages, setStages] = useState<any>(null)
  const [sorter, setSorter] = useState<Sorter>({})
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
    position: [bottomCenter],
    showSizeChanger: false,
  })

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => {
    return [
      {
        href: '/cases',
        title: 'Продажи',
      },
      {
        href: location.pathname,
        title: 'Заказы',
      },
    ]
  }, [])

  useEffect(() => {
    try {
      /* Пытаюсь получить стадии и фильтры */
      getStages()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  useEffect(() => {
    if (stages) {
      getData()
      getAvailableFilters()
    }
  }, [stages])

  /**
   * Получает стадии
   */
  const getStages = useCallback(() => {
    if (!stages) {
      fetchAPI('/api/get-stages/order')
        .then(data => {
          setStages(data)
        })
        .catch(() => {
          console.error('Не удалось получить стадии')
        })
    }
  }, [])

  /**
   * Удаляет запись
   */
  const deleteRecord = useCallback(currentId => {
    fetchAPI(`/api/order/${currentId}`, { method: 'DELETE' })
      .then(() => {
        getStages()
      })
      .catch(() => {
        console.error('Не удалось удалить запись')
      })
  }, [])

  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(
    newState => {
      setPagination({
        ...pagination,
        ...newState,
      })
    },
    [pagination, setPagination],
  )

  /**
   * Получает список доступных фильтров для обращений
   */
  const getAvailableFilters = useCallback(() => {
    const enumsList = { types: ['organizations', 'managers', 'case_cancel_reason', 'case_order_attribute'] }
    fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
      .then(data => {
        const filters = {
          organization: data?.organizations.map((i: IEnum) => ({ label: i.value, value: i.id })) || [],
          stage: stages.map((i: IStage) => ({ label: i.name, value: i.code })),
          manager: data?.managers?.map((i: IEnum) => ({ label: i.value, value: i.id })),
          cancel_reason: data?.case_cancel_reason?.map((i: IEnum) => ({ label: i.value, value: i.id })),
          order_attribute: data?.case_order_attribute?.map((i: IEnum) => ({ label: i.value, value: i.id })),
        }
        setAvailableFilters(filters)
      })
      .catch(() => {
        console.error('Не удалось получить список доступных фильтров')
      })
  }, [stages])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    if (!stages) {
      getStages()
    }
    try {
      setLoading(true)
      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/orders?${objectToUrl(urlParams)}`).then(({ data, pagination, sorter }) => {
        if (data) {
          setData(
            data.map(item => {
              return {
                ...item,
                deleteRecord,
                stage: stages.find(stage => stage.code === item.stage),
              }
            }),
          )
          updatePagination(pagination)
          setSorter(sorter)
        }
        setLoading(false)
      })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [deleteRecord, stages, updatePagination, loading])

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  /**
   * Отправляет на страницу деталки
   * @param record
   */
  const handleRowClick = record => {
    const { id } = record
    history.push(`/order/${id}`)
  }

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const changeFiltersHandler = useCallback(filters => {
    if (!filters?.dates_range) {
      delete(filters?.dates_field)
    }
    const newUrlParams = new URLSearchParams(filters).toString()
    history.push({ search: newUrlParams })
    getData()
  }, [])

  const filters = useMemo(() => {
    const isLeader = sessionData.roles.includes(16)
    const filters = [{
      name: 'manager',
      placeholder: 'Менеджер',
      type: 'select',
      value: currentFilters?.manager,
      options: {
        enum: availableFilters?.manager,
      },
    },
      {
        name: 'id',
        placeholder: 'ID',
        type: 'input-integer',
        value: currentFilters?.id,
        style: { width: '170px' },
      },
      {
        name: 'phone',
        type: 'phone',
        value: currentFilters?.phone,
      },
      {
        name: 'stage',
        placeholder: 'Фильтр по стадии',
        type: 'select',
        value: currentFilters?.stage,
        options: {
          enum: availableFilters?.stage || [],
        },
      },
      {
        type: 'filter_group',
        name: '_',
        items: [
          {
            name: 'dates_range',
            type: 'dates',
            value: currentFilters?.dates_range,
          },
          {
            name: 'dates_field',
            type: 'select',
            value: 'appeal',
            style: { marginLeft: '-1px', width: '160px' },
            options: {
              allowClear: false,
              enum: [
                {
                  label: 'Дата обращения',
                  value: 'appeal',
                },
                {
                  label: 'Дата замера',
                  value: 'measurement',
                },
                {
                  label: 'Дата оплаты',
                  value: 'prepayment',
                },
                {
                  label: 'Дата доставки',
                  value: 'shipping',
                },
                {
                  label: 'Дата монтажа',
                  value: 'mounting',
                },
                {
                  label: 'Дата задачи',
                  value: 'task',
                }
              ],
            },
          },
        ]
      },
      {
        name: 'cancel_reason',
        placeholder: 'Причина отмены',
        type: 'select',
        value: currentFilters?.cancel_reason ? Number(currentFilters.cancel_reason) : undefined,
        options: {
          enum: availableFilters?.cancel_reason || [],
        },
      },
      {
        name: 'order_attribute',
        placeholder: 'Признак заказа',
        type: 'select',
        value: currentFilters?.order_attribute ? Number(currentFilters.order_attribute) : undefined,
        options: {
          enum: availableFilters?.order_attribute || [],
        },
      },
      {
        name: 'customer_name',
        placeholder: 'Контактное лицо клиента',
        type: 'input-string',
        value: currentFilters?.customer_name,
        options: {
          width: '170px',
        },
      },
      {
        name: 'production_number',
        placeholder: 'Номер производственного заказа',
        type: 'input-string',
        value: currentFilters?.production_number,
        options: {
          width: '170px',
        },
      }
    ]
    if (isLeader) {
      filters.unshift({
        name: 'organization',
        placeholder: 'Оргианзация',
        type: 'select',
        value: currentFilters?.organization,
        options: {
          enum: availableFilters?.organization || [],
        },
      })
    }
    return filters as filtersType[]
  }, [availableFilters, currentFilters])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <FiltersList
        filters={filters}
        onChange={changeFiltersHandler}
      />
      <Table
        showSorterTooltip={{ overlay: 'Нажмите для сортировки', placement: 'bottom' }}
        columns={getColumns(isLeader, sorter)}
        rowKey={r => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={record => ({ onClick: () => handleRowClick(record) })}
        scroll={{
          x: 'max-content',
          y: '72vh',
        }}
        size="small"
      />
    </B2BLayout>
  )
}


export default memo(OrdersList)
