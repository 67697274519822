import React, { memo, Suspense, useCallback, useState } from 'react'
import { HStack } from '../../../../../../ui/Stack'
import { Button } from 'antd'
import { classNames } from '../../../../../../lib/classNames/classNames'
import { ModalCreateTask } from '../../../../../../views/modal-create-task-new'
import { ModalAddNote } from '../../../../../../views/modal-add-notes-new'
import ChangeRegionModal from '../../modals/change-region'
import { ModalCancel } from '../../../../../../views/modal-cancel'

interface FormsHeaderProps {
  id: string | number,
  className?: string,
  stage?: number,
  nextStageOnClick?: () => void,
  isDisabled: boolean,
}

const titlesButton = [
  'Консультация',
  'Назначить замер',
  'Договор',
  'Отправить в производство',
  'Доставка',
  'Монтаж',
  'Выполнено',
]

export const FormsHeader: React.FC<FormsHeaderProps> = memo((props: FormsHeaderProps) => {
  const { className, id, stage = 0, nextStageOnClick, isDisabled } = props
  const isDisabledButtons = id === 'new'
  const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState<boolean>(false)
  const [isOpenModalAddNote, setIsOpenModalAddNote] = useState<boolean>(false)
  const [isOpenModalCancel, setIsOpenModalCancel] = useState<boolean>(false)

  const handleNextStageOnClick = useCallback(async () => {
    if (nextStageOnClick) {
      await nextStageOnClick?.()
    }
  }, [nextStageOnClick])

  const handleCancelOnClick = useCallback(() => {
    setIsOpenModalCancel(true)
  }, [setIsOpenModalCancel])

  const onCloseCancel = useCallback(() => {
    setIsOpenModalCancel(false)
  }, [setIsOpenModalCancel])

  const onCloseAddNote = useCallback(() => {
    setIsOpenModalAddNote(false)
  }, [setIsOpenModalAddNote])

  const onCloseCreateTask = useCallback(() => {
    setIsOpenModalCreateTask(false)
  }, [setIsOpenModalCreateTask])

  return (
    <HStack gap={'8'} className={classNames('mapWrapper', {}, [className])}>
      <HStack gap={'8'} max justify={'start'} className={'h3'}>
        Заказ {`№ ${id}`}
      </HStack>
      <HStack gap={'8'} max justify={'end'}>
        <Button
          onClick={() => setIsOpenModalAddNote(true)}
          disabled={isDisabled}
        >
          Добавить заметку
        </Button>
        <Suspense fallback={''}>
          <Button
            onClick={() => setIsOpenModalCreateTask(true)}
            disabled={isDisabled}
          >
            Создать задачу
          </Button>
          <ModalCreateTask
            isOpen={isOpenModalCreateTask}
            onClose={onCloseCreateTask}
            entityType={'case'}
            entityId={id}
          />
        </Suspense>
        <ModalAddNote
          entityId={Number(id)}
          entityType={'case'}
          isOpen={isOpenModalAddNote}
          onClose={onCloseAddNote}
        />
        {!isDisabledButtons && (
          <Suspense fallback={''}>
            <ChangeRegionModal id={+id} disabled={isDisabled}/>
          </Suspense>
        )}
        {
          titlesButton[stage] && stage !== 5 && (
            <Button
              onClick={handleNextStageOnClick}
              disabled={isDisabled}
              type='default'
            >
              {titlesButton[stage] ?? ''}
            </Button>
          )
        }
        {!isDisabledButtons && (
          <Suspense fallback={''}>
            <Button
              danger
              onClick={handleCancelOnClick} disabled={stage === 7 || isDisabled}
            >
              Отмена
            </Button>
            <ModalCancel
              entityId={id}
              entityType='order'
              isOpen={isOpenModalCancel}
              onClose={onCloseCancel}
            />
          </Suspense>
        )}
      </HStack>
    </HStack>
  )
})
